.bigSearch {
    .MuiInput-root {
        font-size: 2rem;
        font-weight: bold;
        &::before {
            border-bottom: none;
        }
        &:hover:not(.Mui-disabled) {
            &::before {
                border-bottom: none;
            }
        }
    }
    .MuiInput-underline:after {
        border-bottom: none;
    }
    .MuiInputAdornment-root svg {
        font-size: 2rem;
    }
    .MuiInputAdornment-positionEnd .MuiIconButton-root {
        color: $dark-blue;
    }
}
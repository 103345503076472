// Core

@import 'core';

// Fonts

@import url('https://fonts.googleapis.com/css?family=Heebo:400,700&display=swap');

// Widgets

@import 'widgets';

// Components

@import 'components';

// Layout Components

@import 'layout-components';

// Layout Blueprints

@import 'layout-blueprints';

@font-face {
  font-family: 'Bulgatti';
  src: url('./Bulgattis.ttf');
  font-weight: 400;
}

.pointer {
  cursor: pointer !important;
}

.suffix {
  position: absolute;
  top: 18px;
  right: 65px;
}

.fade-enter {
  opacity: 0.01;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0.01;
  transition: opacity 300ms ease-in;
}

.full-loader {
  position: absolute;
  height: 80%;
  width: 100%;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.8);
  // opacity: 0.3
}

.MuiInputBase-root.Mui-disabled {
  color: rgba(0, 0, 0, 0.87) !important;
}

.touched {
  label {
    color: blue !important;
  }
}

.fc {
  .fc-button-primary {
    background: $button-gradient !important;
    color: $white !important;
    border-color: $dark-blue !important;

    &.fc-button-active {
      background: $button-active-gradient !important;
      border-color: $darker-blue !important;
    }
  }
}

.fc-event-main {
  // background: linear-gradient(180deg, $gray-900 0%, $dark-blue 100%);
  color: $white !important;
}

.border-dotted-bottom {
  border-bottom: 1px dotted grey;
}

div.dzu-dropzone {
  border: 1px dashed #d9d9d9;
  max-height: 160px;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 5px;
  background: #d3d3d324;
  .dzu-inputLabel {
    margin: 0;
    font-weight: 500;
    font-size: 13px;
    color: #b7b7b7;
  }
  .dzu-inputLabelWithFiles {
    margin: 20px auto;
  }
  .file-box {
    width: 100px;
    height: 100px;

    margin: 10;
  }
}

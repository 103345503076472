// Tables

.table {
  font-size: $font-size-base;

  thead th {
    text-transform: uppercase;
    background: $gray-200;
    font-size: $font-size-sm;
    vertical-align: middle;
  }

  tr {
    transition: $transition-base;
  }

  td, th {
    vertical-align: middle;
  }

  &.table-exiniti {
    border-collapse: separate;
    border-spacing: 0 $layout-spacer / 3;

    thead th{
      padding-left: $layout-spacer / 40;
      padding-right: $layout-spacer / 40;
      padding-bottom: $layout-spacer / 3;
      padding-top: $layout-spacer / 3;
      text-transform: unset;
      background: $light-blue;
      font-size:  $font-size-sm*1.1;
@include media-breakpoint-down(lg) {
      padding-bottom: $layout-spacer / 5;
      padding-top: $layout-spacer / 5;
      font-size:  $font-size-sm/1.25;
}
      font-weight: bold;
      color: $dark-blue;  
      vertical-align: middle;
      border: none;
      white-space:unset !important;

      &:first-child {
        border-top-left-radius: $border-radius;
      }

      &:last-child {
        padding: $border-radius;
        border-top-right-radius: $border-radius;
      }
    }
    
    tbody tr {
      border-radius: $border-radius;
      box-shadow: $box-shadow-layout-bottom;

      td {
        vertical-align: middle;
        padding: 0.2rem;
        font-size:  $font-size-sm*1.1;
  @include media-breakpoint-down(lg) {
        font-size:  $font-size-sm/1.25;
  }
        border: none;
        background-color: $white;

        .nowrap {
          margin: 0 auto;
          max-width: 100px;
          white-space: nowrap !important;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .MuiButton-text {
          font-size:  $font-size-sm*1.1;
    @include media-breakpoint-down(lg) {
          font-size:  $font-size-sm/1.25;
    }
        }

        &:first-child {
          border-top-left-radius: $border-radius;
          border-bottom-left-radius: $border-radius;
        }
  
        &:last-child {
          border-top-right-radius: $border-radius;
          border-bottom-right-radius: $border-radius;
        }
      }
    }
  }

  &.table-alternate {
    thead th {
      text-transform: none;
      background: transparent;
      font-size: $font-size-base * 1.1;
      font-weight: normal;
      color: $gray-900;
    }
  }

  &.table-alternate-spaced {
    border: 0;

    thead {
      th {
        background: transparent;
        font-weight: bold;
        background: none;
        font-size: $font-size-sm / 1.1;
        border: 0;
        padding-top: 0;
        padding-bottom: ($spacer / 1.5);
      }
    }

    tbody {

      tr {
        background: transparent;

        &:hover {
          td::before {
            background: $table-hover-bg;
          }
        }

        td {
          background: transparent;
          position: relative;

          & > * {
            position: relative;
            z-index: 3;
          }

          &::before {
            position: absolute;
            left: 0;
            top: 0;
            transition: $transition-base;
            height: 100%;
            width: 100%;
            content: '';
            background: $white;
            border-top: $gray-400 solid 1px;
            border-bottom: $gray-400 solid 1px;
            pointer-events: none;
          }

          &:first-child {
            &::before {
              @include border-left-radius($border-radius-lg);
              border-left: $gray-400 solid 1px;
            }
          }
          &:last-child {
            &::before {
              @include border-right-radius($border-radius-lg);
              border-right: $gray-400 solid 1px;
            }
          }
        }

        &.divider {
          height: $spacer;
        }
      }

      td {
        border: 0;
      }
    }
  }
}

.wrapper-styled {
    background: $gray-100;
    padding: $layout-spacer/6;
    padding-right: $layout-spacer/2;
    @include media-breakpoint-up(xl) {
      margin-right: -($layout-spacer);
      font-size: $font-size-base;
    }
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
    font-size: $font-size-sm;
}

.wrapper-styled-alternative {
    background: $light-blue;
    padding: $layout-spacer/4;
    margin-right: ($layout-spacer/2);
    @include media-breakpoint-up(xl) {
      margin-right: -($layout-spacer/2);
      font-size: $font-size-base;
    }
    border-radius: $border-radius;
    font-size: $font-size-sm;
}

.table-wrapper {
    padding: $layout-spacer/6;
    background: $gray-100; 
}


.table-wrapper-scroll {
  @media (max-width: 1300px) {
    display: block;
    width: 100%;
  }
}